import { createRouter, createWebHashHistory } from 'vue-router'

const routers = [
  {
    path: '/',
    redirect: '/login/index'
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/index/index.vue'),
    meta: {
      title: '首页',
      index: 1.1,
      keepAlive: true
    }
  },
  {
    path: '/login/index',
    name: 'startUp',
    component: () => import('../views/login/index.vue'),
    meta: { title: '启动页', index: 1.11 }
  },
  {
    path: '/login/precautions',
    name: 'precautions',
    component: () => import('../views/login/precautions.vue'),
    meta: { title: '注意事项', index: 1.111 }
  },
  {
    path: '/login/guide',
    name: 'guide',
    component: () => import('../views/login/loginGuide.vue'),
    meta: { title: '登号指南', index: 1.111 }
  },
  {
    path: '/login/reRegister',
    name: 'reRegister',
    component: () => import('../views/login/reRegister.vue'),
    meta: { title: '重新登录', index: 1.111 }
  },
  {
    path: '/login/login',
    name: 'login',
    component: () => import('../views/login/login.vue'),
    meta: { title: '登录', index: 1.111 }
  },
  {
    path: '/login/backUpLogin',
    name: 'backUpLogin',
    component: () => import('../views/login/backUpLogin.vue'),
    meta: { title: '登录备份', index: 1.111 }
  },
  /* {
   path: '/login/index',
   name: 'login',
   component: () => import('../views/login/backUpIndex.vue'),
   meta: { title: '登录', index: 1.11 }
   },
   {
   path: '/login/login',
   name: 'loginLogin',
   component: () => import('../views/login/backUpLogin.vue'),
   meta: { title: '登录', index: 1.111 }
   },*/
  {
    path: '/login/404',
    name: '404',
    component: () => import('../views/login/404.vue'),
    meta: { title: '错误页', index: 1.12 }
  },
  {
    path: '/login/noRobot',
    name: 'noRobot',
    component: () => import('../views/login/noRobot.vue'),
    meta: { title: '错误页', index: 1.12 }
  },
  {
    path: '/group/openGroup',
    name: 'openGroup',
    component: () => import('../views/group/openGroup.vue'),
    meta: { title: '添加卖货群', index: 1.11 }
  },
  {
    path: '/choseGroup/index',
    name: 'choseGroup',
    component: () => import('../views/choseGroup/index.vue'),
    meta: { title: '选择群', index: 1.11 }
  },
  {
    path: '/keywords',
    name: 'keywords',
    component: () => import('../views/keywords/index.vue'),
    meta: { title: '关键词回复', index: 1.11 }
  },
  {
    path: '/keywordsEdit',
    name: 'keywordsEdit',
    component: () => import('../views/keywordsEdit/index.vue'),
    meta: { title: '设置关键词回复', index: 1.111 }
  },
  {
    path: '/contentEdit',
    name: 'contentEdit',
    component: () => import('../views/contentEdit/index.vue'),
    meta: { title: '关键词内容设置', index: 1.111, keepAlive: true }
  },
  {
    path: '/addText',
    name: 'addText',
    component: () => import('../views/addText/index.vue'),
    meta: { title: '二级页面', index: 1.1 }
  },
  {
    path: '/editLink',
    name: 'editLink',
    component: () => import('../views/editLink/index.vue'),
    meta: { title: '编辑链接信息', index: 1.1111 }
  },
  {
    path: '/editMiniapp',
    name: 'editMiniapp',
    component: () => import('../views/editMiniapp/index.vue'),
    meta: { title: '我的店铺', index: 1.1111 }
  },
  {
    path: '/welcomeWords',
    name: 'welcomeWords',
    component: () => import('../views/welcomeWords/index.vue'),
    meta: { title: '入群欢迎语', index: 1.11 }
  },
  {
    path: '/welcomeEdit',
    name: 'welcomeEdit',
    component: () => import('../views/welcomeEdit/index.vue'),
    meta: { title: '欢迎语内容设置', index: 1.111, keepAlive: true }
  },
  {
    path: '/editText',
    name: 'editText',
    component: () => import('../views/editText/index.vue'),
    meta: { title: '编辑文本消息', index: 1.1111 }
  },
  {
    path: '/selectGroup',
    name: 'selectGroup',
    component: () => import('../views/selectGroup/index.vue'),
    meta: { title: '选择生效群', index: 1.111 }
  },
  {
    path: '/selectGroupGather',
    name: 'selectGroupGather',
    component: () => import('../views/selectGroupGather/index.vue'),
    meta: { title: '选择群', index: 1.111 }
  },
  {
    path: '/selectMemberGather',
    name: 'selectMemberGather',
    component: () => import('../views/selectMemberGather/index.vue'),
    meta: { title: '采集对象', index: 1.1111 }
  },
  {
    path: '/todayPushGoods',
    name: 'todayPushGoods',
    component: () => import('../views/todayPushGoods/index.vue'),
    meta: { title: '今日推品', index: 1.11 }
  },
  {
    path: '/officialMaterial',
    name: 'officialMaterial',
    component: () => import('../views/todayPushGoods/officialMaterial.vue'),
    meta: { title: '官方素材', index: 1.111 }
  },
  {
    // 选择素材
    path: '/checkMaterial',
    name: 'checkMaterial',
    component: () => import('../views/todayPushGoods/checkMaterial.vue'),
    meta: { title: '官方素材', index: 1.11 }
  },
  {
    path: '/pushMaterial',
    name: 'pushMaterial',
    component: () => import('../views/todayPushGoods/pushMaterial.vue'),
    meta: { title: '采集素材', index: 1.11 }
  },
  {
    path: '/todayPushEdit',
    name: 'todayPushEdit',
    component: () => import('../views/todayPushGoods/edit.vue'),
    meta: { title: '推品详情', index: 1.111 }
  },
  {
    path: '/questionAndAnswer',
    name: 'questionAndAnswer',
    component: () => import('../views/questionAndAnswer'),
    meta: { title: '教程与问题', index: 1.11 }
  },
  {
    path: '/setTutorial',
    name: 'setTutorial',
    component: () => import('../views/questionAndAnswer/setTutorial.vue'),
    meta: { title: '使用教程', index: 1.111 }
  },
  {
    path: '/levelUpgradeBanner',
    name: 'levelUpgradeBanner',
    component: () => import('../views/questionAndAnswer/levelUpgradeBanner.vue'),
    meta: { title: '播货助手', index: 1.11 }
  },
  {
    path: '/friendCircle',
    name: 'friendCircle',
    component: () => import('../views/friendCircle'),
    meta: {
      title: '自动发圈'
    }
  },
  {
    path: '/friendCircleEdit',
    name: 'friendCircleEdit',
    component: () => import('../views/friendCircle/edit'),
    meta: {
      title: '新增朋友圈',
      index: 1.111
    }
  },
  {
    path: '/yesterdayPush',
    name: 'yesterdayPush',
    component: () => import('../views/friendCircle/yesterdayPush'),
    meta: {
      title: '昨日推品',
      index: 1.11
    }
  },
  
  {
    path: '/friendCirclePermission',
    name: 'friendCirclePermission',
    component: () => import('../views/friendCirclePermission/friendCirclePermission'),
    meta: {
      title: '谁可以看',
      index: 1.11
    }
  },
  {
    path: '/followMaterial',
    name: 'followMaterial',
    component: () => import('../views/followMaterial/followMaterial'),
    meta: {
      title: '跟发素材',
      index: 1.11
    }
  }
]

const CreateRouter = createRouter

const router = new CreateRouter({
  history: createWebHashHistory(),
  routes: routers
})

export default router
