const globalModules = {
  state: {
    // 托管群信息
    hostingGroupInfo: JSON.parse(sessionStorage.getItem('yh_H5:hostingGroupInfo')) || {
      headImgUrl: '',
      nickName: '暂未设置',
      groupName: '暂未设置'
    },
    // 昨日推品信息
    yesterdayPushedList: JSON.parse(sessionStorage.getItem('yh_h5:yesterdayPushedList')) || [],
    globalData: {},
    contentData: {},
    colorPrimary: '#3254FD',
    colorSuccess: '#38D182',
    transitionName: ''
  },
  getters: {
    // 托管群信息
    hostingGroupInfo: state => state.hostingGroupInfo,
    // 昨日推品信息
    yesterdayPushedList: state => state.yesterdayPushedList,
    globalData: state => state.globalData,
    contentData: state => state.contentData,
    colorPrimary: state => state.colorPrimary,
    colorSuccess: state => state.colorSuccess,
    transitionName: state => state.transitionName
  },
  mutations: {
    // 设置托管群信息
    setHostingGroupInfo(state, data) {
      console.log({...data})
      state.hostingGroupInfo = { ...state.hostingGroupInfo, ...data }
      sessionStorage.setItem('yh_H5:hostingGroupInfo', JSON.stringify(state.hostingGroupInfo))
    },
    resetHostingGroupInfo(state) {
      state.hostingGroupInfo = {}
      sessionStorage.removeItem('yh_H5:yesterdayPushedList')
    },
    // 设置昨日推品信息
    setYesterdayPushedList(state, data) {
      state.yesterdayPushedList = [...state.yesterdayPushedList, ...data]
      sessionStorage.setItem('yh_H5:yesterdayPushedList', JSON.stringify(state.yesterdayPushedList))
    },
    resetYesterdayPushedList(state) {
      state.yesterdayPushedList = []
      sessionStorage.removeItem('yh_H5:hostingGroupInfo')
    },
    setGlobalData(state, data) {
      state.globalData = { ...state.globalData, ...data }
    },
    resetGlobalData(state) {
      state.globalData = {}
    },
    setContentData(state, data) {
      state.contentData = { ...state.contentData, ...data }
    },
    resetContentData(state) {
      state.contentData = {}
    },
    transitionName(state, name) {
      state.transitionName = name
    }
    
  },
  actions: {}
}

export default globalModules
