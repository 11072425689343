import router from './router'
import { store } from './store'

const whiteList = ['/login', '/register']

router.beforeEach(async (to, from, next) => {
  try {
    const { aplus_queue } = window
    if(aplus_queue){
      aplus_queue.push({
        action: 'aplus.sendPV',
        arguments: [
          { is_auto: false },
          {
            param1: `${to.path}1`,
            routerName: to.path
          }
        ]
      })
    }
    // 设置标题
    if (to.meta && to.meta.title) {
      document.title = to.meta.title
      if(from.path == "/keywordsEdit"){
        to.meta.keepAlive = false
      }
    }
    // 设置过度动画
    const toDepth = to.meta ? to.meta.index : 11
    const fromDepth = from.meta ? from.meta.index : 11
    const transitionName = fromDepth ? (toDepth < fromDepth ? 'slide-right' : 'slide-left') : 'fade'
    store.commit('transitionName', transitionName)
  }catch (e) {
    console.log(e)
  }
  next()
})
