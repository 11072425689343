export default {
  install(app) {
    app.directive('bury', {
      created: (el, bind) => {
        el.addEventListener('click', (e) => {
          // 状态是布尔值就说明有参数
          const params = bind.arg ? {
            state: bind.arg
          } : ''
          const { aplus_queue } = window
          aplus_queue.push({
            action: 'aplus.record',
            arguments: [bind.value, 'CLK', {
              param1: '',
              param2: 0,
            }]
          })
        })
      }
    })
  }
}
