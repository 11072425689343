<template>
  <van-checkbox :name="name" :disabled="disabled">
    <slot></slot>
    <template #icon="props">
      <img class="img-icon" :src="props.checked ? ActiveCircleImg : InActiveCircleImg"/>
    </template>
  </van-checkbox>
</template>

<script>
import { computed , ref} from 'vue'
import ActiveCircleImg from '../assets/activeCircle.png'
import InActiveCircleImg from '../assets/inactiveCircle.png'
export default {
  name: 'PgyCheckBox',
  props: {
	  name: {
      type: [String, Number],
      default: ''
    },
	  disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {attrs, slots , emit}){

    return {
      ActiveCircleImg,
      InActiveCircleImg
    }
  },

}
</script>

<style scoped lang="scss">
.img-icon{
  width: 16Px;
  margin-top: -6px;
}

</style>
