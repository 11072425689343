const userModules = {
  state: {
    // localStorage用于过度
    userInfo: JSON.parse(localStorage.getItem('yh_H5:UserInfo') ||
      '{}'),
    justSee: false,
    userIntro: '',
    robotInfo: JSON.parse(localStorage.getItem('yh_H5:robotInfo'))
      || {
        state: 0,
        headImgUrl: '../../assets/avatar.png',
        nickName: '播客机器人'
      }
  },
  getters: {
    userInfo: state => state.userInfo,
    avatarUrl: state => state.userInfo.avatarUrl,
    nickName: state => state.userInfo.nickName,
    robotInfo: state => state.robotInfo
  },
  mutations: {
    setUserInfo(state, info) {
      state.userInfo = { ...state.userInfo, ...info }
      localStorage.removeItem('yh_H5:UserInfo')
      localStorage.setItem('yh_H5:UserInfo', JSON.stringify(state.userInfo))
    },
    setJustSee(state, n) {
      state.justSee = n
    },
    resetUserInfo(state) {
      state.userInfo = {}
      localStorage.removeItem('yh_H5:UserInfo')
    },
    setRobotInfo(state, info) {
      state.robotInfo = { ...state.robotInfo, ...info }
      localStorage.setItem('yh_H5:robotInfo', JSON.stringify(state.robotInfo))
    },
    resetRobotInfo(state) {
      state.robotInfo =
        {
          state: 0,
          headImgUrl: '../../assets/avatar.png',
          nickName: '播客机器人'
        }
      localStorage.removeItem('yh_H5:robotInfo')
    }
  },
  actions: {
    setUserInfo({ commit }, info) {
      commit('setUserInfo', info)
    },
    setRobotInfo({ commit }, info) {
      commit('setRobotInfo', info)
    }
  }
}

export default userModules
