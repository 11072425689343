<template>
  <van-radio :name="name" :key="key" icon-size=".16rem">
    <template #icon="props">
      <img class="img-icon" :src="props.checked ? ActiveCircleImg : InActiveCircleImg"/>
    </template>
    <slot></slot>
  </van-radio>
</template>

<script>
import { computed , ref} from 'vue'
import ActiveCircleImg from '../assets/activeCircle.png'
import InActiveCircleImg from '../assets/inactiveCircle.png'
export default {
  name: 'PgyCheckBox',
  props: {
    name: '',
    key: ''
  },
  setup(props, {attrs, slots , emit}){
    return {
      ActiveCircleImg,
      InActiveCircleImg,

    }
  },

}
</script>

<style scoped lang="scss">
.img-icon{
	height: px(16);
	width: px(16)
  //margin-top: -6px;
	//margin-bottom: px(4);
}

</style>
